import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { MatSidenav } from '@angular/material/sidenav';

export class EncodeResult {
  token: string = "";
  expires: number = 0;
  issued: number = 0;
  id: number = 0;
  email: string = "";
  username: string = "";
  isSuperAdmin: boolean = false;
  isAdminRgpd: boolean = false;
  isAdmin: boolean = false;
  isActive: boolean = false;
  isCA: boolean = false;
}

export class LogInBackDto {
  status: number = 0;
  message: string = "";
  content: EncodeResult|undefined = undefined;
}

@Injectable({
  providedIn: 'root'
})
export class ConnectorService {
  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  async connection(login:string|null, password:string|null){
    await this.http.post<LogInBackDto>(environment.backAPI+'/compte/login', {
      email: login,
      password: password
    }).subscribe(
      (result) => {
        if(result.status === 200 && result.content!= undefined && (result.content.isAdminRgpd || result.content.isCA || result.content.isAdmin || result.content.isSuperAdmin)){
          localStorage.setItem('token', result.content.token);
          localStorage.setItem('expires', result.content.expires.toString());
          localStorage.setItem('issued', result.content.issued.toString());
          localStorage.setItem('id', result.content.id.toString());
          localStorage.setItem('email', result.content.email);
          localStorage.setItem('username', result.content.username);
          localStorage.setItem('isAdmin', (result.content.isAdmin)?'true':'false');
          localStorage.setItem('isAdminRgpd', (result.content.isAdminRgpd)?'true':'false');
          localStorage.setItem('isSuperAdmin', (result.content.isSuperAdmin)?'true':'false');
          localStorage.setItem('isActive', (result.content.isActive)?'true':'false');
          localStorage.setItem('isCA', (result.content.isCA)?'true':'false');
          this.router.navigate(['/stats', { }]);
        }
        else{
          this.router.navigateByUrl('/login?message=no_rights');
        }
      },
      (error) => {
        this.router.navigateByUrl('/login?message=wrong_credentials');
      }
    );
  }

  isAdmin(){
    return localStorage.getItem('isAdmin') === 'true';
  }

  isAdminRgpd(){
    return localStorage.getItem('isAdminRgpd') === 'true';
  }

  isSuperAdmin(){
    return localStorage.getItem('isSuperAdmin') === 'true';
  }

  isCA(){
    return localStorage.getItem('isCA') === 'true';
  }

  disconnection(sidenav?: MatSidenav){
    localStorage.removeItem('token');
    localStorage.removeItem('expires');
    localStorage.removeItem('issued');
    localStorage.removeItem('id');
    localStorage.removeItem('email');
    localStorage.removeItem('username');
    localStorage.removeItem('isAdmin');
    localStorage.removeItem('isAdminRgpd');
    localStorage.removeItem('isSuperAdmin');
    localStorage.removeItem('isActive');
    localStorage.removeItem('isCA');
    this.router.navigate(['/login', { }]);
    if(sidenav !== undefined){
      sidenav.close();
    }
  }

  async check(){
    await this.http.post<LogInBackDto>(environment.backAPI+'/compte/check', {
      token: localStorage.getItem('token')
    }).subscribe(
      (result) => {
        if(result.status === 200 && result.content!= undefined){
          return true;
        }
        else{
          this.disconnection();
          return false;
        }
      }
    );
  }

  isConnected(){
    return this.token() !== undefined
  }

  token(){
    if(localStorage.getItem('token')){
      return localStorage.getItem('token');
    }
    return undefined;
  }

  async reinit(email:string, code:string){
    await this.http.post<boolean>(environment.backAPI+'/compte/reinit-pwd', {
      email: email,
      code: code
    }).subscribe(
      (result) => {
        return result;
      }
    );
  }
}

<div fxLayout="column">
    <div fxFlex="100" fxLayout="column" fxLayoutAlign="center center">
        <h4>Envoyer un message push</h4>
        <mat-form-field>
            <mat-label>Quelle cible ?</mat-label>
            <select matNativeControl required #type>
                <option value="important">Important</option>
                <option value="associative">Associatif</option>
                <option value="prono" selected>Pronostics</option>
            </select>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label>Titre de la notification</mat-label>
            <input matInput placeholder="Ici je mets le titre à afficher" #titre>
          </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label>Corps de la notification</mat-label>
            <textarea matInput #texte placeholder="Ici je mets mon message"></textarea>
        </mat-form-field>
        <button mat-flat-button (click)="sendNotification(type.value, titre.value, texte.value)">Envoyer</button>
    </div>
</div>
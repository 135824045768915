<div fxLayout="column">
  <h1 fxLayout="row" fxFlex="100" fxLayoutAlign="center center">Consultations</h1>
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <button mat-fab color="primary" aria-label="Créer une consultation" (click)="openDialog()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">&nbsp;</div>
  <mat-accordion class="example-headers-align" multi fxLayout="column" fxFlex="100">
    <mat-expansion-panel *ngFor="let item of listConsultations" fxLayout="column" fxFlex="100">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ item.titre }}
        </mat-panel-title>
        <mat-panel-description>
          ID {{ item.id }}&nbsp;|&nbsp;
          <mat-icon>
            {{ (item.type === "credits")?"toll":"" }}
            {{ (item.type === "valeurs")?"rule":"" }}
          </mat-icon>
          &nbsp;
          {{ (item.type === "credits")?"Crédits":"" }}
          {{ (item.type === "valeurs")?"Oui/Non":"" }}
          &nbsp;|&nbsp;
          <mat-icon>
            {{ (item.status === "draft")?"design_services":"" }}
            {{ (item.status === "active")?"play_circle":"" }}
            {{ (item.status === "ended")?"verified":"" }}
            {{ (item.status === "canceled")?"cancel":"" }}
          </mat-icon>
          &nbsp;
          {{ (item.status === "draft")?"Brouillon":"" }}
          {{ (item.status === "active")?"Actif":"" }}
          {{ (item.status === "ended")?"Terminé":"" }}
          {{ (item.status === "canceled")?"Annulé":"" }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      
      <div fxLayout="row" fxLayoutAlign="center center">
        <div fxFlex="100" fxLayoutAlign="center center">
          <mat-accordion class="example-headers-align" multi fxLayout="column" fxFlex="100">
            <mat-expansion-panel fxLayout="column" fxFlex="100">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Modifier la consultation
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div fxLayout="row">
                <mat-form-field fxFlex="100">
                  <mat-label>Titre</mat-label>
                  <input matInput type="text" [ngModel]="item.titre" (ngModelChange)="changeTitre(item.id, $event)">
                </mat-form-field>
              </div>
              <div fxLayout="row">
                <angular-editor [ariaPlaceholder]="'Description'" [ngModel]="item.description" (ngModelChange)="changeDescription(item.id, $event)"></angular-editor>
              </div>

              <div flex="" fxLayout="row" fxLayoutAlign="left">
                <mat-form-field fxFlex="100">
                    <mat-label>Date de début</mat-label>
                    <input matInput [matDatepicker]="pickerDebut" name="date_debut" [ngModel]="item.date_debut" (ngModelChange)="changeDateDebut(item.id, $event)">
                    <mat-hint>JJ/MM/AAAA</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="pickerDebut"></mat-datepicker-toggle>
                    <mat-datepicker #pickerDebut></mat-datepicker>
                </mat-form-field>
              </div>
              
              <div fxLayout="row">
                <mat-form-field fxFlex="100">
                  <mat-label>Heure de début</mat-label>
                  <input matInput type="number" min="0" max="23" [ngModel]="getHourDebut(item.id)" (ngModelChange)="changeHourDebut(item.id, $event)">
                </mat-form-field>
              </div>

              <div flex="" fxLayout="row" fxLayoutAlign="left">
                <mat-form-field fxFlex="100">
                    <mat-label>Date de fin</mat-label>
                    <input matInput [matDatepicker]="pickerFin" name="date_fin" [ngModel]="item.date_fin" (ngModelChange)="changeDateFin(item.id, $event)">
                    <mat-hint>JJ/MM/AAAA</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="pickerFin"></mat-datepicker-toggle>
                    <mat-datepicker #pickerFin></mat-datepicker>
                </mat-form-field>
              </div>
              
              <div fxLayout="row">
                <mat-form-field fxFlex="100">
                  <mat-label>Heure de fin</mat-label>
                  <input matInput type="number" min="0" max="23" [ngModel]="getHourFin(item.id)" (ngModelChange)="changeHourFin(item.id, $event)">
                </mat-form-field>
              </div>

              <div fxLayout="row">
                <mat-form-field fxFlex="100">
                    <mat-label>Type de consultation</mat-label>
                    <mat-select [ngModel]="item.type" name="type" (ngModelChange)="changeType(item.id, $event)">
                      <mat-option value="credits">Crédits <mat-icon>toll</mat-icon></mat-option>
                      <mat-option value="valeurs">Oui/Non <mat-icon>rule</mat-icon></mat-option>
                    </mat-select>
                </mat-form-field>
              </div>
              <div fxLayout="row" *ngIf="isCredits(item.id)">
                <mat-form-field fxFlex="100">
                  <mat-label>Nombre total de crédits</mat-label>
                  <input matInput type="number" min="0" [ngModel]="item.credits" (ngModelChange)="changeCredits(item.id, $event)">
                </mat-form-field>
              </div>
              <div fxLayout="row">
                <mat-form-field fxFlex="100">
                    <mat-label>Statut de la consultation</mat-label>
                    <mat-select [ngModel]="item.status" name="status" (ngModelChange)="changeStatus(item.id, $event)">
                        <mat-option value="draft">Brouillon <mat-icon>design_services</mat-icon></mat-option>
                        <mat-option value="active">Actif <mat-icon>play_circle</mat-icon></mat-option>
                        <mat-option value="ended">Terminé <mat-icon>verified</mat-icon></mat-option>
                        <mat-option value="canceled">Annulé <mat-icon>cancel</mat-icon></mat-option>
                    </mat-select>
                </mat-form-field>
              </div>
              <div fxLayout="row" fxLayoutAlign="center center">
                <div fxFlex="30" fxLayoutAlign="center center">
                  Modifications de la consultation :
                </div>
                <div fxFlex="35" fxLayoutAlign="center center">
                  <button mat-raised-button color="warn" (click)="getConsultations()">Annuler</button>
                </div>
                <div fxFlex="35" fxLayoutAlign="center center">
                  <button mat-raised-button color="primary" (click)="saveConsultation(item.id)">Enregistrer</button>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <div fxFlex="100" fxLayoutAlign="center center">
          &nbsp;
        </div>
      </div>
      <mat-divider></mat-divider>
      <div fxLayout="row" fxLayoutAlign="center center">
        <div fxFlex="100" fxLayoutAlign="center center">
          &nbsp;
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <div fxFlex="50">
          <strong>Questions de la consultation :</strong>
        </div>
        <div fxFlex="50">
          <button mat-raised-button color="primary" aria-label="Créer une consultation" (click)="openDialogQuestion(item.id)">
            <mat-icon>add</mat-icon> Ajouter une question
          </button>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <div fxFlex="100" fxLayoutAlign="center center">
          &nbsp;
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <div fxFlex="100" fxLayoutAlign="center center">
          <mat-accordion class="example-headers-align" multi fxLayout="column" fxFlex="100">
            <mat-expansion-panel *ngFor="let question of getSortedQuestions(item.questions)" fxLayout="column" fxFlex="100">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ question.titre }}
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div fxLayout="row">
                <mat-form-field fxFlex="100">
                  <mat-label>Titre</mat-label>
                  <input matInput type="text" [ngModel]="question.titre" (ngModelChange)="changeTitreQuestion(question, $event)">
                </mat-form-field>
              </div>
              <div fxLayout="row">
                <angular-editor [ariaPlaceholder]="'Description'" [ngModel]="question.description" (ngModelChange)="changeDescriptionQuestion(question, $event)"></angular-editor>
              </div>
              <div fxLayout="row">
                <div fxFlex="50" fxLayoutAlign="end center">Réponse obligatoire ?&nbsp;</div>
                <div fxFlex="50">
                  <mat-slide-toggle [ngModel]="question.obligatoire" (ngModelChange)="changeObligatoire(question, $event)">
                    <span *ngIf="question.obligatoire">Oui</span>
                    <span *ngIf="!question.obligatoire">Non</span>
                  </mat-slide-toggle>
                </div>
              </div>
              <div fxLayout="row">
                <div fxFlex="50" fxLayoutAlign="end center">Volontaires acceptés ?&nbsp;</div>
                <div fxFlex="50">
                  <mat-slide-toggle [ngModel]="question.volontariat" (ngModelChange)="changeVolontariat(question, $event)">
                    <span *ngIf="question.volontariat">Oui</span>
                    <span *ngIf="!question.volontariat">Non</span>
                  </mat-slide-toggle>
                </div>
              </div>
              <div fxLayout="row">
                <mat-form-field fxFlex="100">
                  <mat-label>Lien à afficher</mat-label>
                  <input matInput type="text" [ngModel]="question.lien" (ngModelChange)="changeLienQuestion(question, $event)">
                </mat-form-field>
              </div>
              
              <div fxLayout="row" fxLayoutAlign="center center">
                <div fxFlex="30" fxLayoutAlign="center center">
                  Modifications de la question :
                </div>
                <div fxFlex="35" fxLayoutAlign="center center">
                  <button mat-raised-button color="warn" (click)="getConsultations()">Annuler</button>
                </div>
                <div fxFlex="35" fxLayoutAlign="center center">
                  <button mat-raised-button color="primary" (click)="saveQuestion(item.id, question.id)">Enregistrer</button>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>

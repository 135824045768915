<div fxLayout="column">
    <a href="/#/account-v2" fxFlex="100" fxLayoutAlign="center">
        Aller vers la recherche avancée >>>
    </a>
</div>
<div fxLayout="column">
    <div fxFlex="100" fxLayout="row" fxLayoutAlign="center">
        <mat-form-field class="example-form-field">
            <input matInput type="text" placeholder="ID, Email ou nom/prénom de recherche" [(ngModel)]="search" (ngModelChange)="getAccounts()" [matAutocomplete]="autoGroup">
            <button matSuffix mat-icon-button aria-label="Rechercher" (click)="getAccounts()">
                <mat-icon>search</mat-icon>
            </button>
            <mat-autocomplete #autoGroup="matAutocomplete">
                <mat-option *ngFor="let currentAccount of accountSearch | async" (onSelectionChange)="setAccount(currentAccount)">
                    {{ displaySearch(currentAccount) }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
</div>
<div fxLayout="column" *ngIf="account !== null">
    <div fxLayout="column">
        <h1 flex="" fxLayout="row" fxLayoutAlign="center">Mon compte</h1>
        <div flex="" fxLayout="row" fxLayoutAlign="left">
            <div fxFlex="50" class="mainElement">Adresse email :</div>
            <div fxFlex="50">{{account.email}}</div>
        </div>
        <div flex="" fxLayout="row" fxLayoutAlign="left">
            <div fxFlex="50" class="mainElement">Téléphone :</div>
            <div fxFlex="50">{{ (account.phone)?account.phone.number.international:"" }}</div>
        </div>
        <div flex="">&nbsp;</div>
        <h1 flex="" fxLayout="row" fxLayoutAlign="center">
            <button mat-raised-button color="accent" (click)="reinitPwd()">Réinitialiser le mot de passe</button>
        </h1>
        <div flex="">&nbsp;</div>
        <h1 flex="" fxLayout="row" fxLayoutAlign="center">Mes Socios</h1>
    </div>
    <div fxLayout="column" class="sociodetails" *ngFor="let socio of account.socios" [id]="'socio-'+socio.id" isEditing="0">
        <h2 flex="" fxLayout="row" fxLayoutAlign="left">Socio#{{socio.techId}}
            <button mat-icon-button color="primary" class="editButton readMode" aria-label="Modifier les infos de ce socio" (click)="editMode(socio.id,true)">
                <mat-icon>edit</mat-icon>
            </button>
            <a mat-icon-button color="primary" class="editButton" *ngIf="socio.discord_id !== null && socio.discord_id !== ''" target="_blank" [href]="'https://discordapp.com/users/'+socio.discord_id">
                <mat-icon>discord</mat-icon>
            </a>
        </h2>
        <div flex="" fxLayout="row" class="readMode" fxLayoutAlign="left">
            <div fxFlex="50" class="mainElement">Socio ?</div>
            <div fxFlex="50">
                <mat-icon *ngIf="socio.isSocio" style="color:green">verified</mat-icon>
                <mat-icon *ngIf="!socio.isSocio" style="color:red">close</mat-icon>
            </div>
        </div>
        <div flex="" fxLayout="row" class="readMode" fxLayoutAlign="left">
            <div fxFlex="50" class="mainElement">Adhérent ?</div>
            <div fxFlex="50">
                <mat-icon *ngIf="socio.isAdherent" style="color:green">verified</mat-icon>
                <mat-icon *ngIf="!socio.isAdherent" style="color:red">close</mat-icon>
            </div>
        </div>
        <div flex="" fxLayout="row" class="readMode" fxLayoutAlign="left">
            <div fxFlex="50" class="mainElement">Nom :</div>
            <div fxFlex="50">{{socio.nom}}</div>
        </div>
        <div flex="" fxLayout="row" fxLayoutAlign="left" class="editMode">
            <mat-form-field fxFlex="100">
                <mat-label>Nom de famille</mat-label>
                <input matInput placeholder="Nom de famille" name="nom" type="text" value="{{socio.nom}}">
            </mat-form-field>
        </div>
        <div flex="" fxLayout="row" class="readMode" fxLayoutAlign="left">
            <div fxFlex="50" class="mainElement">Prénom :</div>
            <div fxFlex="50">{{socio.prenom}}</div>
        </div>
        <div flex="" fxLayout="row" fxLayoutAlign="left" class="editMode">
            <mat-form-field fxFlex="100">
                <mat-label>Prénom</mat-label>
                <input matInput placeholder="Prénom" name="prenom" type="text" value="{{socio.prenom}}">
            </mat-form-field>
        </div>
        <div flex="" fxLayout="row" class="readMode" fxLayoutAlign="left">
            <div fxFlex="50" class="mainElement">Adresse email :</div>
            <div fxFlex="50">{{socio.mail}}</div>
        </div>
        <div flex="" fxLayout="row" class="readMode" fxLayoutAlign="left">
            <div fxFlex="50" class="mainElement">Date de naissance :</div>
            <div fxFlex="50">{{socio.date_naissance | date:'dd/MM/yyyy'}}</div>
        </div>
        <div flex="" fxLayout="row" fxLayoutAlign="left" class="editMode">
            <mat-form-field fxFlex="100">
                <mat-label>Date de naissance</mat-label>
                <input matInput [matDatepicker]="picker" name="date_naissance" [value]="socio.date_naissance">
                <mat-hint>JJ/MM/AAAA</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
        <div flex="" fxLayout="row" fxLayoutAlign="left">
            <div fxFlex="" class="mainElement">Adresse postale :</div>
        </div>
        <div flex="" fxLayout="row" class="readMode" fxLayoutAlign="left">
            <div fxFlex="20">&nbsp;</div>
            <div fxFlex="30">Rue :</div>
            <div fxFlex="50">{{socio.adresse}}</div>
        </div>
        <div flex="" fxLayout="row" fxLayoutAlign="left" class="editMode">
            <mat-form-field fxFlex="100">
                <mat-label>Rue</mat-label>
                <input matInput placeholder="Rue" name="adresse" type="text" value="{{socio.adresse}}">
            </mat-form-field>
        </div>
        <div flex="" fxLayout="row" class="readMode" fxLayoutAlign="left">
            <div fxFlex="20">&nbsp;</div>
            <div fxFlex="30">Code postal :</div>
            <div fxFlex="50">{{socio.code_postal}}</div>
        </div>
        <div flex="" fxLayout="row" fxLayoutAlign="left" class="editMode">
            <mat-form-field fxFlex="100">
                <mat-label>Code postal</mat-label>
                <input matInput placeholder="Code postal" name="code_postal" type="text" value="{{socio.code_postal}}">
            </mat-form-field>
        </div>
        <div flex="" fxLayout="row" class="readMode" fxLayoutAlign="left">
            <div fxFlex="20">&nbsp;</div>
            <div fxFlex="30">Ville :</div>
            <div fxFlex="50">{{socio.ville}}</div>
        </div>
        <div flex="" fxLayout="row" fxLayoutAlign="left" class="editMode">
            <mat-form-field fxFlex="100">
                <mat-label>Ville</mat-label>
                <input matInput placeholder="Ville" name="ville" type="text" value="{{socio.ville}}">
            </mat-form-field>
        </div>
        <div flex="" fxLayout="row" class="readMode" fxLayoutAlign="left">
            <div fxFlex="20">&nbsp;</div>
            <div fxFlex="30">Pays :</div>
            <div fxFlex="50">{{socio.pays}}</div>
        </div>
        <div flex="" fxLayout="row" fxLayoutAlign="left" class="editMode">
            <mat-form-field fxFlex="100">
                <mat-label>Choisir le pays</mat-label>
                <mat-select [value]="capitalizeFirstLetter(socio.pays)" name="pays">
                    <mat-option *ngFor="let country of countries" [value]="country.name">
                        {{country.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div flex="" class="editMode">
            Valider les modifications ? &nbsp;
            <button mat-fab color="warn" class="validateButtons" aria-label="Annuler les modifications" (click)="editMode(socio.id,false)">
                <mat-icon>cancel</mat-icon>
            </button>
            <button mat-fab color="primary" class="validateButtons rightButton" aria-label="Valier les modifications" (click)="validate(socio.id)">
                <mat-icon>task_alt</mat-icon>
            </button>
        </div>
    </div>
</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Account } from '../account/account.dto';
import { ConnectorService } from '../connector.service';
import { Observable } from 'rxjs';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

export enum ROLES {
  BASIC = 'basic',
  CA = 'conseil_administration',
  ADMIN = 'admin',
  ADMIN_RGPD = 'admin_rgpd',
  SUPERADMIN = 'super_admin',
}

export enum ProfileStatus {
  INACTIVE = 'inactive',
  FIRSTCONNECTION = 'first_connection',
  ACTIVE = 'active',
  BLOCKED = 'blocked',
}

@Component({
  selector: 'app-accountv2',
  templateUrl: './accountv2.component.html',
  styleUrls: ['./accountv2.component.scss']
})
export class Accountv2Component implements OnInit {
  form!: FormGroup;
  roles = Object.values(ROLES);
  statuses = Object.values(ProfileStatus);
  
  // Tableau pour stocker tous les comptes retournés
  accounts: Account[] = [];
  // Tableau pour stocker uniquement la page actuelle
  pagedAccounts: Account[] = [];

  // Paramètres de pagination
  pageSize = 10;
  pageIndex = 0;
  totalResults = 0;

  // Contrôle l'affichage de la partie "plus de détails"
  showDetails: boolean = false;

  // Récupération du paginator depuis le template
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private http: HttpClient,
    private connector: ConnectorService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    // Initialisation du formulaire avec valeurs "non spécifié"
    this.form = this.fb.group({
      search: [''],
      isSocio: [null],
      isAdherent: [null],
      hasDiscord: [null],
      limit: [null, Validators.min(1)],
      role: [[]],
      status: ['']
    });
  }

  toggleDetails(): void {
    this.showDetails = !this.showDetails;
  }

  resetField(field: string): void {
    this.form.get(field)?.reset();
  }

  onSubmit(): void {
    if (this.form.valid) {
      const rawData = this.form.value;
      const formData: any = {};

      // Ajout des champs renseignés
      if (rawData.search && rawData.search.trim() !== '') {
        formData.search = rawData.search;
      }
      if (rawData.isSocio !== null) {
        formData.isSocio = rawData.isSocio;
      }
      if (rawData.isAdherent !== null) {
        formData.isAdherent = rawData.isAdherent;
      }
      if (rawData.hasDiscord !== null) {
        formData.hasDiscord = rawData.hasDiscord;
      }
      if (rawData.limit !== null) {
        formData.limit = rawData.limit;
      }
      if (rawData.role && rawData.role.length > 0) {
        formData.role = rawData.role;
      }
      if (rawData.status && rawData.status !== '') {
        formData.status = rawData.status;
      }

      // Appel HTTP à votre webservice
      this.http.post<Account[]>(
        environment.backAPI + '/compte/admin/advanced-search',
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.connector.token()
          }
        }
      ).subscribe(
        (result) => {
          // Si tout va bien, on stocke le résultat et on initialise la pagination
          this.accounts = result || [];
          this.totalResults = this.accounts.length;
          this.pageIndex = 0;
          this.updatePagedAccounts();
        },
        (error: HttpErrorResponse) => {
          // En cas d'erreur, on peut déconnecter l'utilisateur ou gérer l'erreur autrement
          this.connector.disconnection();
          this.accounts = [];
          this.totalResults = 0;
          this.updatePagedAccounts();
        }
      );
    }
  }

  // Méthode pour mettre à jour les comptes affichés selon la page sélectionnée
  updatePagedAccounts(): void {
    const start = this.pageIndex * this.pageSize;
    const end = start + this.pageSize;
    this.pagedAccounts = this.accounts.slice(start, end);
  }

  // Méthode déclenchée par le paginator lors d'un changement de page
  onPageChange(event: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.updatePagedAccounts();
  }
}

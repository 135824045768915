import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { ConnectorService } from '../connector.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.scss']
})
export class CommunicationComponent {
  constructor(
    private http: HttpClient,
    private connector: ConnectorService,
    private router: Router
  ){}

  async sendNotification(type:string, titre:string, texte:string){
    const toSend = {
      type: type,
      title: titre,
      body: texte
    }
    const _this = this;
    (await this.http.post<any>(environment.backAPI+'/notifications/send-massive', toSend,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ this.connector.token()
      }
    })).subscribe(
      (result) => {
        if(result !== undefined && !(result instanceof HttpErrorResponse)){
          alert(result.message);
          return result;
        }
        else{
          this.connector.disconnection();
          return [];
        }
      },
      (error) => {
        this.connector.disconnection();
        return [];
      }
    );
  }
}

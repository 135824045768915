<mat-card class="account-card" fxLayout="column" fxLayoutGap="15px">
    <mat-card-title>Filtrer les comptes</mat-card-title>
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <!-- Champ toujours visible (Recherche) -->
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Recherche</mat-label>
            <input matInput placeholder="Tapez votre recherche" formControlName="search">
            <button mat-icon-button matSuffix type="button" (click)="resetField('search')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        
        <!-- Bouton pour afficher/masquer les détails -->
        <div fxLayout="row" fxLayoutAlign="center center" style="margin-bottom:10px">
          <button mat-button type="button" (click)="toggleDetails()">
            {{ showDetails ? 'Moins de détails' : 'Plus de détails' }}
            <mat-icon>{{ showDetails ? 'expand_less' : 'expand_more' }}</mat-icon>
          </button>
        </div>
        
        <!-- Partie détails (affichée conditionnellement) -->
        <div *ngIf="showDetails">
          <!-- Ligne 1 : Deux champs tri-état -->
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="5px">
            <div fxFlex>
              <mat-form-field appearance="fill" class="full-width">
                <mat-label>Est Socio</mat-label>
                <mat-select formControlName="isSocio">
                  <mat-option [value]="null">Non filtré</mat-option>
                  <mat-option [value]="true">Oui</mat-option>
                  <mat-option [value]="false">Non</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxFlex>
              <mat-form-field appearance="fill" class="full-width">
                <mat-label>Est Adhérent</mat-label>
                <mat-select formControlName="isAdherent">
                  <mat-option [value]="null">Non filtré</mat-option>
                  <mat-option [value]="true">Oui</mat-option>
                  <mat-option [value]="false">Non</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          
          <!-- Ligne 2 : Deux champs -->
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="5px">
            <div fxFlex>
              <mat-form-field appearance="fill" class="full-width">
                <mat-label>Avoir Discord</mat-label>
                <mat-select formControlName="hasDiscord">
                  <mat-option [value]="null">Non filtré</mat-option>
                  <mat-option [value]="true">Oui</mat-option>
                  <mat-option [value]="false">Non</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxFlex>
              <mat-form-field appearance="fill" class="full-width">
                <mat-label>Limit (min 1)</mat-label>
                <input matInput type="number" formControlName="limit" placeholder="Limit">
                <mat-error *ngIf="form.get('limit')?.hasError('min')">
                  La limite doit être supérieure à zéro.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          
          <!-- Ligne 3 : Deux champs -->
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="5px">
            <div fxFlex>
              <mat-form-field appearance="fill" class="full-width">
                <mat-label>Rôle(s)</mat-label>
                <mat-select formControlName="role" multiple>
                  <mat-option *ngFor="let r of roles" [value]="r">
                    {{ r }}
                  </mat-option>
                </mat-select>
                <button mat-icon-button matSuffix type="button" (click)="resetField('role')">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <div fxFlex>
              <mat-form-field appearance="fill" class="full-width">
                <mat-label>Status</mat-label>
                <mat-select formControlName="status">
                  <mat-option [value]="''">Non filtré</mat-option>
                  <mat-option *ngFor="let s of statuses" [value]="s">
                    {{ s }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        
        <!-- Bouton de soumission toujours visible -->
        <div fxLayout="row" fxLayoutAlign="center center">
          <button mat-raised-button color="primary" type="submit" class="full-width">Valider</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
  
  <!-- Affichage des résultats avec pagination -->
  <div *ngIf="accounts && accounts.length > 0; else noResult" class="result-container" fxLayout="column" fxLayoutGap="15px">
    <h2>Résultats ({{ totalResults }})</h2>
    <mat-accordion>
      <mat-expansion-panel *ngFor="let account of pagedAccounts">
        <mat-expansion-panel-header>
          <div class="custom-header">
            <div class="custom-header-title">
              {{ account.email }} ({{ account.status }})
            </div>
            <div class="custom-header-description">
              Profile: {{ account.profile }}
            </div>
          </div>
        </mat-expansion-panel-header>        
        <div>
          <p><strong>ID :</strong> {{ account.id }}</p>
          <p>
            <strong>Téléphone :</strong>
            {{ account.phone?.number?.international || 'Non renseigné' }}
          </p>
          <!-- Affichage des socios -->
          <div *ngIf="account.socios && account.socios.length > 0">
            <p><strong>Socios :</strong></p>
            <div *ngFor="let socio of account.socios">
              <mat-card class="socio-card">
                <mat-card-header>
                  <mat-card-title>{{ socio.nom }} {{ socio.prenom }}</mat-card-title>
                  <mat-card-subtitle>ID : {{ socio.id }}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                  <p><strong>Email :</strong> {{ socio.mail }}</p>
                  <p><strong>Date de naissance :</strong> {{ socio.date_naissance | date:'shortDate' }}</p>
                  <p><strong>Adresse :</strong> {{ socio.adresse }}</p>
                  <p><strong>Code Postal :</strong> {{ socio.code_postal }}</p>
                  <p><strong>Ville :</strong> {{ socio.ville }}</p>
                  <p><strong>Pays :</strong> {{ socio.pays }}</p>
                  <p><strong>Discord ID :</strong> {{ socio.discord_id || 'Non renseigné' }}</p>
                  <div *ngIf="socio.cotisation">
                    <p><strong>Cotisation ID :</strong> {{ socio.cotisation.id }}</p>
                    <p><strong>Date d'adhésion :</strong> {{ socio.cotisation.date_adhesion | date:'shortDate' }}</p>
                    <p><strong>Somme :</strong> {{ socio.cotisation.somme }}</p>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    
    <!-- Paginator -->
    <mat-paginator [length]="totalResults"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="[5, 10, 25, 50]"
                   (page)="onPageChange($event)">
    </mat-paginator>
  </div>
  
  <ng-template #noResult>
    <p>Aucun résultat trouvé.</p>
  </ng-template>
  
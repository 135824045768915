import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AccountComponent } from './account/account.component';
import { ConsComponent } from './cons/cons.component';
import { DocumentsComponent } from './documents/documents.component';
import { StatsComponent } from './stats/stats.component';
import { PronosComponent } from './pronos/pronos.component';
import { RetraitComponent } from './retrait/retrait.component';
import { CommunicationComponent } from './communication/communication.component';
import { Accountv2Component } from './accountv2/accountv2.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'stats', component: StatsComponent },
  { path: 'account', component: AccountComponent },
  { path: 'account-v2', component: Accountv2Component },
  { path: 'consultations', component: ConsComponent },
  { path: 'documents', component: DocumentsComponent },
  { path: 'pronos', component: PronosComponent },
  { path: 'retraits', component: RetraitComponent },
  { path: 'communication', component: CommunicationComponent },
  { path: '', component: LoginComponent },
  { path: '404', component: LoginComponent},
  { path: '**', component: LoginComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component } from '@angular/core';
import { ConnectorService } from '../connector.service';
import { NavigationEnd, Router } from '@angular/router';

class navMenu {
  link: string = "";
  title: string = "";
  icon: string = "";
}

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  isConnected = true;
  menuToDisplay:navMenu[] = [];

  constructor(private connector: ConnectorService, private router: Router){}

  ngOnInit(): void {
    this.setIsConnected();
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
        this.setIsConnected();
      }
    });
  }

  setIsConnected(){
    this.isConnected = this.connector.isConnected();
    if(this.isConnected){
      this.menuToDisplay = this.getConnectedMenu();
    }else{
      this.menuToDisplay = [];
    }
  }

  getConnectedMenu(){
    const menuToDisplay = [];
    menuToDisplay.push(
      {
        link: "/stats",
        title: "Accueil",
        icon: "home"
      }
    );
    menuToDisplay.push(
      {
        link: "/pronos",
        title: "Pronostics",
        icon: "check_box"
      }
    );
    if(this.connector.isCA()){
      menuToDisplay.push(
        {
          link: "/communication",
          title: "Communication",
          icon: "forward_to_inbox"
        }
      );
    }
    if(this.connector.isAdminRgpd()){
      menuToDisplay.push(
        {
          link: "/account",
          title: "Comptes",
          icon: "person"
        }
      );
    }
    menuToDisplay.push(
      {
        link: "/consultations",
        title: "Consultations",
        icon: "how_to_vote"
      }
    );
    menuToDisplay.push(
      {
        link: "/documents",
        title: "Documents",
        icon: "folder_open"
      }
    );
    menuToDisplay.push(
      {
        link: "/retraits",
        title: "Scan retraits",
        icon: "qr_code_scanner"
      }
    );

    return menuToDisplay;
  }

  disconnect(){
    this.connector.disconnection();
  }
}
